import { throttle } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import ProductListboxComponent from '@/components/ProductListboxComponent/ProductListboxComponent';
import Autocomplete from '@/components/ui/Autocomplete/Autocomplete';
import dametisApi from '@/helpers/dametisApi';
import useFormatMessage from '@/hooks/useFormatMessage';

import { Popper } from './ProductAutocompleteField.style';

const MIN_CHAR_LIMIT_FOR_SUGGESTIONS = 2;

const ProductAutocompleteField = ({
  onBlur,
  onChange,
  inputRef,
  value,
  helperText,
  insideDialog,
  only,
  ...restProps
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [open, setOpen] = useState(false);
  const textFieldRef = useRef(null);
  const formatMessage = useFormatMessage();
  const [options, setOptions] = useState([]);

  const fetchSuggestions = async queryValue => {
    const trimmedValue = queryValue.trim();
    const shouldFetchSuggesstions =
      trimmedValue && trimmedValue.length >= MIN_CHAR_LIMIT_FOR_SUGGESTIONS;

    if (shouldFetchSuggesstions) {
      const response = await dametisApi.queryProductTypes(
        trimmedValue,
        1,
        only
      );

      if (response.status >= 200 && response.status < 300) {
        const { data } = response;
        const { data: suggestions, pagination } = data;
        const { currentPage, totalPages } = pagination;

        if (suggestions.length) {
          setSuggestions(suggestions);
          setCurrentPage(currentPage);
          setHasNextPage(currentPage < totalPages);
        } else {
          emptySuggestions();
        }
      }
    } else {
      emptySuggestions();
    }
  };

  const fetchNextPageSuggestions = async (queryValue, page) => {
    const trimmedValue = queryValue.trim();
    const shouldFetchSuggesstions =
      trimmedValue && trimmedValue.length >= MIN_CHAR_LIMIT_FOR_SUGGESTIONS;

    if (shouldFetchSuggesstions) {
      const response = await dametisApi.queryProductTypes(
        trimmedValue,
        page,
        only
      );
      if (response.status >= 200 && response.status < 300) {
        const { data } = response;
        const { data: suggestions, pagination } = data;
        const { currentPage, totalPages } = pagination;

        setSuggestions(prevSuggestions => [...prevSuggestions, ...suggestions]);
        setCurrentPage(currentPage);
        setHasNextPage(currentPage < totalPages);
      }
    }
  };

  const fetchSuggestionsThrottled = useMemo(
    () => throttle(fetchSuggestions, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (open) {
      fetchSuggestionsThrottled(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, open]);

  useEffect(() => {
    let mappedList = [];
    const trimmedValue = value.trim();

    if (suggestions.length) {
      suggestions.map(item => {
        const {
          productTypes: children,
          name: productGroupName,
          invoice_basis: invoiceBasis,
        } = item;
        const hasChildren = Array.isArray(children) && children.length > 0;
        const { id, name } = item;

        if (hasChildren) {
          const childrenWithGroupName = children.map(child => ({
            ...child,
            productGroupName,
            invoiceBasis,
          }));
          mappedList = [
            ...mappedList,
            { id, name, head: true },
            ...childrenWithGroupName,
          ];
        } else {
          mappedList = [...mappedList, item];
        }
      });
    } else {
      mappedList = [
        trimmedValue && trimmedValue.length >= MIN_CHAR_LIMIT_FOR_SUGGESTIONS
          ? {
              id: 1,
              name: trimmedValue,
              selectManual: true,
            }
          : {
              id: 1,
              name: formatMessage('rental_object_suggestion_empty_label'),
              emptyLabel: true,
            },
      ];
    }

    setOptions(mappedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions]);

  const onSuggestionClick = (e, suggestion) => {
    const {
      name,
      id: productReferenceId,
      salesforce_id: salesforceId,
      selectManual,
      productGroupName,
      product_group_id: productGroupId,
      automated_offer: automatedOffer,
      send_automated_offer: sendAutomatedOffer,
      min_lead_time: minLeadTime,
      rental_price_basis: rentalPriceBasis,
      rental_price: rentalPrice,
      invoiceBasis,
    } = suggestion;

    if (selectManual) {
      onChange({ name });
    } else
      onChange({
        name,
        productReferenceId,
        salesforceId,
        productGroupName,
        productGroupId,
        automatedOffer: Boolean(automatedOffer),
        sendAutomatedOffer: Boolean(sendAutomatedOffer),
        minLeadTime,
        rentalPriceBasis,
        rentalPrice,
        invoiceBasis,
      });

    emptySuggestions();
  };

  const onValueChange = (value, reason) => {
    if (reason === 'input') {
      onChange({
        name: value,
      });
    }
  };

  const emptySuggestions = () => {
    setSuggestions([]);
    setCurrentPage(1);
    setHasNextPage(false);
  };

  return (
    <>
      <Autocomplete
        id="productAutocompleteField"
        label={formatMessage('dr_form_machine')}
        ListboxComponent={ProductListboxComponent}
        ListboxProps={{
          inputRef: textFieldRef,
          loadMoreItems: () => fetchNextPageSuggestions(value, currentPage + 1),
          hasNextPage: hasNextPage,
          inputValue: value,
          'data-cy': 'ProductAutocompleteFieldListbox',
        }}
        PopperComponent={Popper}
        slotProps={{ popper: { insideDialog } }}
        TextFieldProps={{
          inputRef: e => {
            textFieldRef.current = e;
            if (inputRef) {
              if (typeof inputRef === 'function') {
                inputRef(e);
              } else {
                inputRef.current = e;
              }
            }
          },
          onBlur,
          helperText,
          ...restProps,
        }}
        options={options}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.name
        }
        renderOption={(props, option) => [props, option]}
        renderGroup={params => params}
        onChange={(e, value) => onSuggestionClick(e, value)}
        onInputChange={(e, value, reason) => onValueChange(value, reason)}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        openOnFocus
        blurOnSelect
        disableClearable
        fullWidth
        freeSolo
        filterOptions={x => x}
        value={value}
      />
    </>
  );
};

ProductAutocompleteField.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  insideDialog: PropTypes.bool,
  only: PropTypes.oneOf(['rentable', 'sellable']),
};

ProductAutocompleteField.defaultProps = {
  value: '',
  required: false,
  error: false,
  helperText: '',
  inputRef: null,
  insideDialog: false,
};

export default ProductAutocompleteField;
